import CTCarousel from './libraries/carousel';

/* Homepage JS */

// homepage carousel
const carousel = new CTCarousel();
carousel.init({
	id: '.section--carousel',
	slidenav: false,
	animate: true,
	startAnimated: true,
	additionalClasses: '',
});

// Hack for spotlight
document.addEventListener('load', function () {
	const gridSpacer = document.createElement('div');
	gridSpacer.style.width = '2rem !important';
	gridSpacer.classList.add('GridLayout__cell');
	const spotlightGrid = document.querySelector('.GridLayout__grid');
	spotlightGrid.appendChild(gridSpacer);
});

/* ============================================================
 * Store Offers JS
 * ============================================================ */

const showOffers = document.querySelector('#showOffers');

showOffers.addEventListener('click', function () {
	/* Show Offers on click */
	document.querySelector('.store-offers .container').classList.toggle('openOffers');
	document.querySelector('.store-offers').classList.toggle('open');
	document.querySelector('.store-offers .container--content').classList.toggle('show');

	if (document.querySelector('.store-offers').classList.contains('open')) {
		document.querySelector(
			'.store-offers .container .title-container .button span.close',
		).style.display = 'block';
		document.querySelector(
			'.store-offers .container .title-container .button span.view',
		).style.display = 'none';
	} else {
		document.querySelector(
			'.store-offers .container .title-container .button span.close',
		).style.display = 'none';
		document.querySelector(
			'.store-offers .container .title-container .button span.view',
		).style.display = 'block';
	}

	/* Flip arrow SVG on click */
	document
		.querySelector('.store-offers .container .title-container')
		.classList.toggle('arrowFlip');

	/* Change view text on click to close - not working */
	document.querySelector(
		'.store-offers .container .title-container .toggle-offers .button--view',
	).innnerHTML = 'CLOSE';
});
